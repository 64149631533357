<template>
	<div :style="buyBg">
		<Header :title="$t('assets.buyWdtForSelf')"></Header>
	
		<div class="column-center-content main-content">
			<div class="row-content banlance-content">
				<label style="color: #FF0000; font-weight: bold">{{$t('common.usdt')}}:</label>
				<label style="color: #FF0000; margin-left: 5px;" v-text="usdt"></label>
			</div>
			
			<div class="row-content shadow-box-content buy-box">
				<div class="column-center-content">
					<img src="../../../../assets/home/buy/moneybag.svg" class="type-img" style="margin-top: 10px;"/>
					<label style="color: #FFFFFF;">{{$t('common.usdt')}}</label>
				</div>
				<i class="fa fa-arrow-right" aria-hidden="true" style="color: #FFFFFF;"></i>
				<div class="column-center-content">
					<img src="../../../../assets/home/buy/wallet.svg" class="type-img"/>
					<label style="color: #FFFFFF;">{{$t('common.wdt')}}</label>
				</div>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('assets.buyWdtAmount')}}</label>
				<v-text-field type="number" :placeholder="$t('assets.buyWdtAmount_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="wdtAmount"></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('assets.needUsdt')}}</label>
				<v-text-field readonly :placeholder="$t('assets.needUsdt_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="usdtAmount"></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('assets.payPwd')}}</label>
				<v-text-field :placeholder="$t('assets.payPwd_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="pwd" type="password"></v-text-field>
			</div>
			
			<div class="row-content form-content" style="justify-content: flex-start;">
				<label class="form-title">{{$t('common.usdt')}}：</label>
				<label class="form-title" v-text="usdtAmount"></label>
				<label class="form-title" style="margin-left: 40px;">{{$t('common.wdt')}}：</label>
				<label class="form-title" v-text="wdtAmount"></label>
			</div>
			
			<div class="btn-content">
				<v-btn rounded block @click="showConfirmTip" style="background-color: #009245; color: white; height: 40px;">
					{{$t('common.submit')}}
				</v-btn>
			</div>
		</div>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="confirmTipVisible" popup-transition="popup-fade">
			<BuyConfirmTip :tipInfo="tipInfo"></BuyConfirmTip>
		</mt-popup>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="successTipVisible" popup-transition="popup-fade">
			<BuySuccessTip :tipInfo="tipInfo"></BuySuccessTip>
		</mt-popup>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../../../components/NavHeader.vue'
	import BuyConfirmTip from '../../../../components/BuyConfirmTip.vue'
	import BuySuccessTip from '../../../../components/BuySuccessTip.vue'
	import { Indicator } from 'mint-ui';
	import { Toast } from 'mint-ui';
	
	export default {
		name: 'BuyNasdqaForSelf',
		components: {
			Header,
			BuyConfirmTip,
			BuySuccessTip
		},
		watch: {
			wdtAmount:function (val) {
				this.usdtAmount = val * 0.2
			}
		},
		data() { 
			return {
				buyBg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				usdt: JSON.parse(localStorage.getItem('walletInfo')).usdt ? JSON.parse(localStorage.getItem('walletInfo')).usdt : 0,
				wdtAmount: 0,
				usdtAmount: 0,
				pwd: '',
				confirmTipVisible: false,
				successTipVisible: false,
				tipInfo: {
					title: '',
					type: '',
					coinType: '',
					toUser: '',
					amount: 0,
					usdt: 0,
					Wdt: 0
				}
			} 
		},
		methods: {
			showConfirmTip() {
				
				let wdt = this.$data.wdtAmount;
				let usdt = this.$data.usdtAmount;
				let pwd = this.$data.pwd;
				
				if(null == wdt || '' == wdt) {
					Toast({
						message: this.$i18n.t("assets.buyWdtAmount_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(wdt <= 0) {
					Toast({
						message: this.$i18n.t("assets.buyWdtAmount_error"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == pwd || '' == pwd) {
					Toast({
						message: this.$i18n.t("assets.payPwd_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == usdt || '' == usdt) {
					usdt = 0;
				}
				sessionStorage.setItem('refreshInfo', '1');
				this.$data.tipInfo = {
					title: 'assets.buyWdtForSelf',
					type: 'self',
					coinType: 'Wdt',
					toUser: '',
					amount: wdt,
					usdt: usdt,
					wdt: 0
				}
				this.$data.confirmTipVisible = true;
			},
			
			closePop() {
				this.$data.confirmTipVisible = false;
				this.$data.successTipVisible = false;
			},
			
			buy() {
				this.$data.confirmTipVisible = false;
				
				let wdt = this.$data.wdtAmount;
				let usdt = this.$data.usdtAmount;
				let pwd = this.$data.pwd;
				
				if(null == usdt || '' == usdt) {
					usdt = 0;
				}
				
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				let params = {
					amount: wdt,
					usdt: usdt,
					wdt: 0,
					production: 'Wdt',
					target: '',
					pwd: pwd
				}
				
				this.$request.trade(
					params
				).then(()=>{
					Indicator.close();
					this.$data.tipInfo = {
						title: 'assets.buyWdtForSelf',
						type: 'self',
						coinType: 'Wdt',
						toUser: '',
						amount: wdt,
						usdt: usdt,
						wdt: 0
					}
					this.$data.successTipVisible = true;
				});
			},
			
			goBack() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style>
	.buy-box {
		width: 90%; 
		height: 140px; 
		background-color: #fd5424; 
		padding: 10px 10%; 
		justify-content: space-between;
	}
	
	.type-img {
		width: auto; 
		height: 50px; 
		margin-bottom: 10px;
	}
</style>
